import React from 'react';

import styles from './TagList.module.scss';

const TagList = ({ tags, title }) => (
  <>
    {title && <h1 className={styles.title}>{title}</h1>}
    <div className={styles.tags}>
      { (tags || []).map(tag => <a className={styles.tag} href={`/tags/${tag.data.slug}/`} key={tag.data.slug}>{tag.data.title}</a>)}
    </div>
  </>
);

export default TagList;

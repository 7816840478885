import React from 'react';

import styles from './FeatureList.module.scss';
import InfoBox from '../../info/InfoBox/InfoBox';
import PurchaseBox from '../../info/PurchaseBox/PurchaseBox';
import unified from 'unified';
import html from 'remark-html';
import markdown from 'remark-parse';

const FeatureList = ({ items }) => {
  return (
    <div className={styles.wrapper}>
      {items.map(item => {
        return (
          <div className={styles.item}>
            <h1 className={styles.title}>
              <span className={styles.number}>{item.entry}.</span> {item.title}
            </h1>

            <div className={styles.container}>
              <InfoBox items={item.info} />
              <div className={styles.content}>
                {item.image && (
                  <img
                    className={styles.image}
                    src={item.image.localFiles[0].childImageSharp.fluid.src}
                  />
                )}
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: unified()
                      .use(markdown)
                      .use(html)
                      .processSync(item.description),
                  }}
                />
              </div>
              <PurchaseBox item={item} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FeatureList;

/* eslint-disable no-undef, react/prop-types, react/no-danger */
import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../../components/furniture/Layout/Layout';
import unified from 'unified';
import html from 'remark-html';
import markdown from 'remark-parse';
import CollectionTitle from '../../components/collections/CollectionTitle/CollectionTitle';
import TagList from '../../components/tags/TagList/TagList';
import FeatureList from '../../components/features/FeatureList/FeatureList';
import removeEmptyRows from '../../utils/data/removeEmptyRows';

const FeatureTemplate = ({ data, location }) => {
  const {
    detail: { id: featureId, data: feature },
    list: { edges: unfilteredList }
  } = data;

  const list = removeEmptyRows(unfilteredList);

  const items = list.map(item => {
    item = item.node;

    return {
      id: item.id,
      ...item.data,
      info: [
        item.data.info1
          ? {
            name: feature.info1,
            value: item.data.info1,
          }
          : undefined,
        item.data.info2
          ? {
            name: feature.info2,
            value: item.data.info2,
          }
          : undefined,
        item.data.info3
          ? {
            name: feature.info3,
            value: item.data.info3,
          }
          : undefined,
        item.data.info4
          ? {
            name: feature.info4,
            value: item.data.info4,
          }
          : undefined,
        item.data.info5
          ? {
            name: feature.info5,
            value: item.data.info5,
          }
          : undefined,
        item.data.info6
          ? {
            name: feature.info6,
            value: item.data.info6,
          }
          : undefined,
      ].filter(Boolean),
    }
  });

  return (
    <Layout location={location} pageTitle={feature.title}>
      <CollectionTitle
        title={feature.title}
        subtitle={`The top ${items.length}`}
      />
      <TagList tags={feature.tags} />

      <div
        dangerouslySetInnerHTML={{
          __html: unified()
            .use(markdown)
            .use(html)
            .processSync(feature.description),
        }}
      />

      <p>
        <strong>Last updated:</strong> {feature.date}
      </p>

      <FeatureList items={items} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query Feature($slug: String) {
    detail: airtable(table: { eq: "features" }, data: { slug: { eq: $slug } }) {
      id
      data {
        slug
        title
        image {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 600, quality: 40) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        description
        date
        info1
        info2
        info3
        info4
        tags {
          data {
            title
            slug
          }
        }
      }
    }
    list: allAirtable(filter: { table: { eq: $slug } }) {
      edges {
        node {
          id
          data {
            title
            entry
            image {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 40) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            description
            info1
            info2
            info3
            info4
            link1url
            link1text
            link2url
            link2text
            link3url
            link3text
          }
        }
      }
    }
  }
`;

export default FeatureTemplate;

import React from 'react';

import PurchaseItem from './../PurchaseItem/PurchaseItem';

import styles from './PurchaseBox.module.scss';

const PurchaseBox = ({ item }) => {
  return (
    <div className={styles.wrapper}>
      <ul className={styles.purchaseOptions}>
        {item && item.link1url ? <PurchaseItem country="US" text={item.link1text} url={item.link1url} /> : null}
        {item && item.link2url ? <PurchaseItem country="UK" text={item.link2text} url={item.link2url} /> : null}
        {item && item.link3url ? <PurchaseItem country="Canada" text={item.link3text} url={item.link3url} /> : null}
      </ul>
    </div>
  );
};

export default PurchaseBox;

import React from 'react';

import styles from './PurchaseItem.module.scss';
import classNames from 'classnames';

const PurchaseItem = ({ country, text, url }) => {
  return (
    <li className={styles.store}>
      <div className={classNames(styles.flag, styles[`flag${country}`])} />
      <a href={url}>{text}</a>
    </li>
  );
};

export default PurchaseItem;

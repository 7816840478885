import React from 'react';

import styles from './CollectionTitle.module.scss';

const CollectionTitle = ({ title, subtitle }) => (
  <>
    <h1 className={styles.title}>{title}</h1>
    <h2 className={styles.subtitle}>{subtitle}</h2>
  </>
);

export default CollectionTitle;

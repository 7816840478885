import React from 'react';

import styles from './InfoBox.module.scss';
import InfoItem from '../InfoItem/InfoItem';

const InfoBox = ({ items }) => {
  return (
    <div className={styles.wrapper}>
      <ul className={styles.list}>
        {items.map(item => (
          <InfoItem item={item} />
        ))}
      </ul>
    </div>
  );
};

export default InfoBox;
